<template>
  <div class="login">
    <section>
      <h1 class="subtitle">Please enter your email to get the access code.</h1>
      <b-field label="Email" :type="formDisplay.email.type" :message="formDisplay.email.message">
        <div class="send-code-area">
          <b-input v-model="email" @input="emailValidation()" ></b-input>
        </div>
      </b-field>
      <b-button @click="sendAccessCode" size="is-small" class="mb-5" :disabled="!this.email" expanded>
        Send access code
      </b-button>

      <h1 class="subtitle">After receiving the access code, insert it and the new password in the fields below.</h1>

      <b-field label="Access Code">
        <b-input type="number" v-model="accessCode" >
        </b-input>
      </b-field>

      <b-field
        label="New Password"
        :type="formDisplay.password.type"
        :message="formDisplay.password.message"
      >
        <b-input @input="verifyPasswordRequirements" placeholder="New Password" type="password" v-model="password" password-reveal>
        </b-input>
      </b-field>

      <div class="button-area">
        <b-field>
          <b-button @click="backToLogin" type="is-warning">
            Back
          </b-button>
        </b-field>
        <b-field>
          <b-button @click="verifyAccount" type="is-success" :disabled="!this.accessCode || !this.password || this.formDisplay.password.type === 'is-danger'">
            Verify
          </b-button>
        </b-field>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'login-form',
  data () {
    // TODO: Remove these defaults
    return {
      email: '',
      accessCode: '',
      password: '',
      formDisplay: {
        password: { type: '', message: '' },
        email: { type: '', message: '' }
      }
    }
  },
  methods: {
    verifyPasswordRequirements () {
      const requirements = /^(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).{8,}$/

      if (!this.password.match(requirements)) {
        this.formDisplay.password.type = 'is-danger'
        this.formDisplay.password.message = 'Password must contain a minimum of 8 characters, one uppercase letter, one special character and one number'
      } else {
        this.formDisplay.password.type = 'is-success'
        this.formDisplay.password.message = ''
      }
    },
    emailValidation () {
      const emailRequirement = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
      if (!this.email.match(emailRequirement)) {
        this.formDisplay.email.type = 'is-danger'
        this.formDisplay.email.message = 'Please enter a proper email.'
      } else {
        this.formDisplay.email.type = 'is-success'
        this.formDisplay.email.message = ''
      }
    },
    async sendAccessCode () {
      this.$emit('loggingIn', true)
      this.$store.dispatch('auth/sendAccessCode', { email: this.email })
        .then((res) => {
          this.$emit('loggingIn', false)
          this.$buefy.toast.open({
            message: 'Access code sent! Check your inbox',
            type: 'is-success',
            duration: 5000,
            position: 'is-top'
          })
        })
        .catch((err) => {
          console.error(err)
          this.$emit('loggingIn', false)
          this.$buefy.toast.open({
            message: 'It was not possible to send the access code to the given email address',
            type: 'is-danger',
            duration: 5000,
            position: 'is-top'
          })
          this.$emit('loggingIn', false)
        })
    },
    async verifyAccount () {
      this.$emit('loggingIn', true)
      this.$store.dispatch('auth/verifyAccount', { email: this.email, accessCode: this.accessCode, newPassword: this.password })
        .then((res) => {
          this.$emit('loggingIn', false)
          this.$buefy.toast.open({
            message: 'Account verified! Please, login with your new password',
            type: 'is-success',
            duration: 5000,
            position: 'is-top'
          })
          this.$router.push({ path: '/login' })
        })
        .catch((err) => {
          console.error(err)
          this.$emit('loggingIn', false)
          this.$buefy.toast.open({
            message: 'It was not possible to verify your account with the given code',
            type: 'is-danger',
            duration: 5000,
            position: 'is-top'
          })
          this.$emit('loggingIn', false)
        })
    },
    backToLogin () {
      this.$router.push({ path: '/login' })
    }
  },
  computed: {

  },
  mounted () {
    loadData()
  }
}
function loadData () {
  return {}
}
</script>

<style lang="scss" scoped>
  h3 {
      font-size: large;
      padding-top: 100px;
      text-align: center;
  }
  .send-code-area {
    display: flex;
    flex-direction: column;
    button {
      margin-top: 10px;
    }
  }
  .button-area {
    display: flex;
    justify-content: space-between;
  }
</style>
